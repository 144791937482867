<template>
    <div style="font-family: A;color: #1296db">
        <div style="  display: flex; justify-content: center;padding: 0px 10%;font-size: 18px;gap:10%">
            <div style="width:60%"> <img src="https://qiniu.shunmingelectronic.com/guanwang/guanwangmeter.png"
                    width="100%">
                <div>电磁流量计转换器</div>
            </div>

        </div>
        <div style="padding: 60px 10%;text-align: left; font-size: 22px;color: #1296db;">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 本公司的核心产品涵盖电磁流量计转换器系列，包括电池供电型、两线制、冷
            热计量专用、太阳能供电型电磁流量计转换器等多种型号。我们的电磁流量计转换器以高精度、高线性度和卓越的稳定性为特色。特别是电池供电电磁流量计，
            集成了GPRS和技术，支持数据上传至云平台，便于数据管理和服务。
        </div>

        <div style="width: 100%;margin-top: 20px;">
            <img src="https://qiniu.shunmingelectronic.com/guanwang/m_title1.jpg" width="100%" />
        </div>
        <div style="width: 100%;margin-top: 20px;">
            <img src="https://qiniu.shunmingelectronic.com/guanwang/m_title2.jpg" width="100%" />
        </div>
        <div style="width: 100%;margin-top: 20px;">
            <img src="https://qiniu.shunmingelectronic.com/guanwang/m_title3.jpg" width="100%" />
        </div>
        <div style="width: 100%;margin-top: 20px;">
            <img src="https://qiniu.shunmingelectronic.com/guanwang/m_title4.jpg" width="100%" />
        </div>
        <div style="width: 100%;margin-top: 20px;">
            <img src="https://qiniu.shunmingelectronic.com/guanwang/ts1.jpg" width="100%" />
        </div>
        <div style="width: 100%;margin-top: 20px;">
            <img src="https://qiniu.shunmingelectronic.com/guanwang/ts2.jpg" width="100%" />
        </div>
        <div style="width: 100%;margin-top: 20px;">
            <img src="https://qiniu.shunmingelectronic.com/guanwang/ts3.jpg" width="100%" />
        </div>
        <div style="width: 100%;margin-top: 20px;">
            <img src="https://qiniu.shunmingelectronic.com/guanwang/ts4.jpg" width="100%" />
        </div>
        <div style="width: 100%;margin-top: 20px;">
            <img src="https://qiniu.shunmingelectronic.com/guanwang/ts5.jpg" width="100%" />
        </div>
        <div style="width: 100%;margin-top: 20px;">
            <img src="https://qiniu.shunmingelectronic.com/guanwang/ts6.jpg" width="100%" />
        </div>
        <div style="width: 100%;margin-top: 20px;">
            <img src="https://qiniu.shunmingelectronic.com/guanwang/ts7.jpg" width="100%" />
        </div>
        <div style="width: 100%;margin-top: 20px;">
            <img src="https://qiniu.shunmingelectronic.com/guanwang/ts8.jpg" width="100%" />
        </div>
        <div style="width: 100%;margin-top: 20px;">
            <img src="https://qiniu.shunmingelectronic.com/guanwang/ts9.jpg" width="100%" />
        </div>

        <div style="font-size: 40px;margin-bottom: 50px;margin-top: 150px;">应用场景</div>
        <div class="use">
            <div>水处理与供水:</div>
            <div style="text-align: center;margin-top: 15px;font-size: 20px;">用于市政供水和污水处理的精确流量测量。</div>
        </div>
        <div class="use">
            <div>化工行业:</div>
            <div style="text-align: left;margin-top: 15px;font-size: 20px;">测量腐蚀性液体的流量，如酸、碱等。</div>
        </div>
        <div class="use">
            <div>食品与饮料:</div>
            <div style="text-align: left;margin-top: 15px;font-size: 20px;">测量牛奶、果汁等食品液体。</div>
        </div>
        <div class="use">
            <div>制药行业:</div>
            <div style="text-align: left;margin-top: 15px;font-size: 20px;">用于药液流量的精确控制。</div>
        </div>
        <div class="use">
            <div>冶金与矿业:</div>
            <div style="text-align: left;margin-top: 15px;font-size: 20px;">测量矿浆、泥浆等导电液体的流量。</div>
        </div>
        <div class="use">
            <div>能源与石油:</div>
            <div style="text-align: left;margin-top: 15px;font-size: 20px;">应用于发电厂和石油开采中的流量测量。</div>
        </div>

    </div>
</template>
<script>
export default {
    name: 'FlowMeterDevice',
}
</script>
<style scoped>
.use {
    font-size: 26px;
    padding: 30px 5%;
}</style>
