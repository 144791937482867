<template>
    <div style="font-family: A;color: #1296db">
        <div style="margin:0px 20px; display: flex; justify-content: center;" ref="videoContainer">
            <video ref="videoElement" style="width: 80%;" controls muted>
                <source src="https://qiniu.shunmingelectronic.com/guanwang/mymeter.mp4" type="video/mp4">
                Your browser does not support the video tag.
            </video>
        </div>
        <!-- <div style="width:100%;padding-top:20px; "> <img
                src="https://qiniu.shunmingelectronic.com/guanwang/guanwangmeterpt.png" width="60%">
        </div> -->
        <div>
            <div
                style="font-size: 30px;font-weight: 700;color: #1296db;margin-bottom: 20px;margin-top: 100px; text-align: left;">
                我们能为您做什么</div>
            <div style="text-align: left; font-size: 20px;color: #1296db; width: 70%;">
                智能仪表数据云平台，引领仪表管理新时代！平台支持数字孪生技术，打造逼真的场景建模，
                为客户提供沉浸式的操作体验。实现实时数据采集，全面展示流量信息及各类告警通知，确保关键数据尽在掌握。
                此外，平台智能跟踪仪表在线状态及使用情况，可按年、季度、月、日多维度呈现数据，通过直观的饼图与柱状图轻松实现分析。
                不论是单一仪表还是整个系统，我们为客户提供高效精准的智能化管理解决方案。
            </div>
            <div style="font-size: 30px;font-weight: 700;color: #1296db;margin-bottom: 10px;margin-top:200px;">产品特点
            </div>
            <div style="padding: 30px 20%;text-align: left; font-size: 20px;color: #1296db;">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 利用4G网络技术，平台实现了高效的数据传输，快速且可靠地将
                大量仪表数据传输至云端，确保实时性能与可靠性。平台在数据传输
                过程中采用了优化的通信协议和智能分发技术，即使在复杂的网络环
                境中也能降低延迟，保持数据完整性与传输效率。这为客户提供了稳
                定、无缝的远程数据访问和实时监控，全面满足高性能数据管理需求。
            </div>
        </div>




        <div style="">
            <div class="product-jj">
                <div style="display: flex;  align-items: center;width: 40%;justify-content: center;">
                    <div style="margin-right: 0px;">
                        <div>
                            <div class="product-text">
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;通过精准建模，我们实现了物理设备与虚拟环境的实时同步。平台支持可定制的场景建模，提供直观的可视化体验。展示仪表流量、设备状态和告警信息，向客户提供高效的监控与管理体验。
                            </div>
                        </div>
                    </div>
                </div>
                <div style="width: 60%;">
                    <img src="../../assets/web/2.png" width="70%" />
                </div>
            </div>

            <div class="product-jj">
                <div style="width: 60%;">
                    <img src="../../assets/web/3.png" width="70%" />
                </div>
                <div style="display: flex;  align-items: center;width: 40%;justify-content: center;">
                    <div style="margin-right: 0px;">
                        <div>


                            <div class="product-text">
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;借助数字孪生技术，客户可以动态模拟设备运行和数据流动，同时访问历史数据，大幅提升管理效率和决策能力，为智能运维提供创新解决方案。
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="width:100%;padding-top:280px; "> <img src="../../assets/web/4.png" width="60%">
            </div>
            <div style="padding: 60px 0%;text-align: left; font-size: 20px;color: #1296db;">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                如有您有需要，我们可以通过先进的数字孪生技术精确复制您的工作环境。从设备布局到数据流动，我们的平台提供逼真的虚拟表示，支持实时数据同步和交互，提升效率并优化决策。
            </div>
            <div class="product-jj">
                <div style="width: 60%;">
                    <img src="../../assets/web/5.png" width="80%" />
                </div>
                <div style="display: flex;  align-items: center;width: 40%;justify-content: center;">
                    <div style="margin-right: 0px;">
                        <div>
                            <div class="product-text">
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;精准的仪表数据监控与历史趋势分析，帮助用户高效管理流量数据，轻松应对数据世界的复杂性!
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="font-size: 30px;font-weight: 700;color: #1296db;margin-bottom: 30px;padding-top:150px;">实时监控仪表数据
            </div>
            <div style="width:100%; "> <img src="../../assets/web/6.png" width="70%">
            </div>
            <div class="use">
                <div style="width: 25%;text-align: left;">在线状态:</div>
                <div style="width:75%;text-align: left;">仪表运行状况一目了然</div>
            </div>
            <div class="use">
                <div style="width: 25%;text-align: left;">实时瞬时流量:</div>
                <div style="width:75%;text-align: left;">精确捕捉每一刻的流量变化。</div>
            </div>
            <div class="use">
                <div style="width: 25%;text-align: left;">总累积量:</div>
                <div style="width:75%;text-align: left;">精准记录仪表的累计用量。</div>
            </div>
            <div class="use">
                <div style="width: 25%;text-align: left;">正、负累计流量:</div>
                <div style="width:75%;text-align: left;">细分正负方向累计值，数据更清晰</div>
            </div>
            <div style="font-size: 30px;font-weight: 700;color: #1296db;margin-bottom: 30px;padding-top:200px;">
                交互式弹窗，深入数据分析
            </div>
            <div class="product-jj">
                <div style="width: 60%;">
                    <img src="../../assets/web/7.png" width="80%" />
                </div>
                <div style="display: flex;  align-items: center;width: 40%;justify-content: center;">
                    <div style="margin-right: 0px;">
                        <div>
                            <div class="product-text">
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                通过瞬时流量的历史趋势图和表格数据，累积流量和正/负累积流量，使您每一刻能够轻松跟踪仪表数据变化。并且为日、月、季度、年等区间设计的统计弹窗，通过柱状图与表格结合的形式，清晰呈现不同时段的用量数据，支持多维度对比分析，助您优化用能策略。
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="product-jj">
                <div style="display: flex;  align-items: center;width: 40%;justify-content: center;">
                    <div style="margin-right: 0px;">
                        <div>
                            <div class="product-text">
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;综合统计模块提供了系统内所有仪表的宏观概览，支持按年、季度、月和日查询，帮助您全面了解不同时间段内的使用模式。通过直观的饼图展示区域使用分布，您可以轻松掌握各个区域的消耗情况。该模块还提供了告警数量和占比统计，确保及时识别潜在问题。此外，您还可以查询特定时间区间内每个仪表的详细使用情况以及各个时间周期内所有仪表的总使用量，从而实现高效的数据管理和战略决策。
                            </div>
                        </div>
                    </div>
                </div>
                <div style="width: 60%;">
                    <img src="../../assets/web/8.png" width="70%" />
                </div>
            </div>
            <div style="font-size: 30px;font-weight: 700;color: #1296db;margin-bottom: 30px;padding-top:200px;">
                高效的参数设置
            </div>
            <div style="width:100%;padding-top:70px; "> <img src="../../assets/web/10.png" width="60%"></div>
            <div style="padding: 60px 0%;text-align: left; font-size: 20px;color: #1296db;">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                系统全面支持电磁流量计的核心参数设置，为用户提供灵活便捷的调整能力。从流量控制到设备优化，用户可轻松根据实际应用需求配置仪表参数，确保设备在各种复杂场景下都能保持高效精准的运行状态。通过智能化管理，操作变得简单，效率大幅提升。
            </div>

            <div style="font-size: 30px;font-weight: 700;color: #1296db;padding-top:150px;">
                异常设备监控
            </div>
            <div style="padding: 30px 15%;text-align: center; font-size: 20px;color: #1296db;">
                告警统计分析模块提供全面的设备告警监控和统计分析功能帮助管理人员及时了解设备运行状态
            </div>
            <!-- <div style="width:100%;padding-top:40px; "> <img src="../../assets/web/9.png" width="60%">
            </div>
            <div style="padding: 60px 20%;text-align: center; font-size: 20px;color: #1296db;">
                <li style="text-align: left;margin-bottom: 20px;">支持多种告警类型，包括励磁告警、空管告警以及流量上下限告警。</li>
                <li style="text-align: left;margin-bottom: 20px;">清晰显示告警的开始和结束时间，实时闪烁指示器显示告警状态，直观反映当前的告警情况。</li>
                <li style="text-align: left;margin-bottom: 20px;">直观展示各设备告警频次，快速定位问题设备。</li>
                <li style="text-align: left;margin-bottom: 20px;">饼图展示不同类型告警占比，助力分析主要故障类型。</li>
            </div> -->
            <div class="product-jj">
                <div style="display: flex;  align-items: center;width: 50%;justify-content: center;">
                    <div style="margin-right: 2px;font-size: 20px;color: #1296db;">
                        <div>
                            <li style="text-align: left;margin-bottom: 20px;">支持多种告警类型，包括励磁告警、空管告警以及流量上下限告警。</li>
                            <li style="text-align: left;margin-bottom: 20px;">清晰显示告警的开始和结束时间，实时闪烁指示器显示告警状态，直观反映当前的告警情况。
                            </li>
                            <li style="text-align: left;margin-bottom: 20px;">直观展示各设备告警频次，快速定位问题设备。</li>
                            <li style="text-align: left;margin-bottom: 20px;">饼图展示不同类型告警占比，助力分析主要故障类型。</li>
                        </div>
                    </div>
                </div>
                <div style="width: 50%;">
                    <img src="../../assets/web/9.png" width="100%" />
                </div>
            </div>
            <div class="product-jj">
                <div style="width: 50%;">
                    <img src="../../assets/web/12.png" width="100%" />
                </div>
                <div style="display: flex;  align-items: center;width: 50%;justify-content: center;">
                    <div style="margin-left: 50px;">
                        <div>
                            <div class="product-text">
                                <li style="text-align: left;margin-bottom: 20px;">
                                    自动检测并实时展示所有处于离线状态的设备，确保管理员能即时获悉任何设备异常情况。通过直观的界面，用户可以轻松识别哪些设备未正常连接，从而采取快速措施恢复其在线状态。
                                </li>
                                <li style="text-align: left;margin-bottom: 20px;">
                                    准确计算每个设备的离线时长(以小时为单位)，并清晰地在列表中显示。管理员可以迅速了解每个设备的停机时间，帮助分析故障可能的原因。</li>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div style="font-size: 30px;font-weight: 700;color: #1296db;padding-top:150px;margin-bottom: 60px;">
                其它特色
            </div>
            <div style="padding: 25px 10%;text-align: left; display: flex; justify-content: center; ">
                <div style="margin-right: 20px;"><img src="../../assets/update.png" style="width: 80px;height: 80px;">
                </div>
                <div>
                    <div style="text-align: left;font-size: 25px;color: #1296db;">远程升级，让运维更轻松</div>
                    <div style="text-align: left;margin-top: 10px;font-size: 20px;color: #1296db;">
                        系统配备设备软件远程升级功能，无需现场操作即可一键完成设备固件的在线更新。不仅缩短了维护周期，还减少了人员投入与成本支出极大提高了设备运行的安全性和稳定性。智能升级，助力企业优化运维效率。
                    </div>
                </div>
            </div>
            <div style="padding: 25px 10%;text-align: left; display: flex; justify-content: center; ">
                <div style="margin-right: 20px;"><img src="../../assets/inter.png" style="width: 80px;height: 80px;">
                </div>
                <div>
                    <div style="text-align: left;font-size: 25px;color: #1296db;">多语言支持，服务全球用户</div>
                    <div style="text-align: left;margin-top: 10px;font-size: 20px;color: #1296db;">
                        为了服务更广泛的用户群体，系统支持包括中文、英文、印度语土耳其语等多种语言，真正实现无障碍操作。用户可根据需求自由切换语言，保证管理体验的便捷性和一致性。无论身处何地，系统始终贴近您的需求。
                    </div>
                </div>
            </div>
            <div style="padding: 25px 10%;text-align: left; display: flex; justify-content: center; ">
                <div style="margin-right: 20px;"><img src="../../assets/chuangxin.png"
                        style="width: 80px;height: 80px;"></div>
                <div>
                    <div style="text-align: left;font-size: 25px;color: #1296db;">整合创新，优化设备管理</div>
                    <div style="text-align: left;margin-top: 10px;font-size: 20px;color: #1296db;">
                        凭借对参数设置的灵活控制、远程升级的智能化特性，以及多语言覆盖的广泛适配能力，系统不仅为流量计的管理提供了精准高效的解决方案，还通过智能化的技术手段帮助用户降低成本、优化流程，为企业迈向数字化管理赋能。
                    </div>
                </div>
            </div>
            <div style="padding: 25px 10%;text-align: left; display: flex; justify-content: center; ">
                <div style="margin-right: 20px;"><img src="../../assets/dingzhi.png"
                        style="width: 80px;height: 80px;"></div>
                <div>
                    <div style="text-align: left;font-size: 25px;color: #1296db;">经销商专属管理权限与品牌定制服务</div>
                    <div style="text-align: left;margin-top: 10px;font-size: 20px;color: #1296db;">本平台为经销商提供可分配终端用户的专属管理账号，实现终端用户分级管理,同时支持自定义企业logo和界面标题，助力合作伙伴实现灵活运营并强化品牌形象。
                    </div>
                </div>
            </div>
            <div style="width: 100%;margin-top: 180px;">
                    <img src="https://qiniu.shunmingelectronic.com/guanwang/cpmh-266095f07g.jpg" width="70%" />
                </div>
            <!-- <div style="font-size: 26px;"><span style="width: 160px;">水处理与供水:</span><span>用于市政用水和工厂</span></div> -->
        </div>





    </div>

</template>
<script>
export default {
    data() {
        return {
            hasUserInteracted: false,
        };
    },
    mounted() {
        window.addEventListener('scroll', this.handleUserInteraction);
        this.createIntersectionObserver();
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleUserInteraction);
    },
    methods: {
        handleUserInteraction() {
            this.hasUserInteracted = true;
        },
        createIntersectionObserver() {
            const videoElement = this.$refs.videoElement;

            const observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting && this.hasUserInteracted) {
                        videoElement.play().catch(error => {
                            console.log("Auto-play was prevented: ", error);
                        });
                    } else {
                        videoElement.pause();
                    }
                });
            }, {
                threshold: 0.5
            });

            observer.observe(videoElement);
        }
    }
};
</script>
<style scoped>
.product-jj {
    display: flex;
    justify-content: center;
    margin-top: 150px;
}

.product-kind-name {
    font-size: 28px;
    color: #1296db;
}

.product-text {
    padding: 50px 0px;
    color: #1296db;
    text-align: left;
    font-size: 20px;
}

.use {
    font-size: 26px;
    display: flex;
    justify-content: center;
    padding: 12px 20%;
}

.tongdian {
    display: flex;
    margin-bottom: 100px;
    margin-left: 5%;
}
</style>