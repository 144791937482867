<template>
    <div style="font-family: A;color: #1296db">
        <div style="margin:0px 10px;" ref="videoContainer">
            <video ref="videoElement" style="width: 100%;" controls muted>
                <source src="https://qiniu.shunmingelectronic.com/guanwang/mymeter.mp4" type="video/mp4">
                Your browser does not support the video tag.
            </video>
        </div>

        <div>
            <div style="font-size: 24px;font-weight: 700;color: #1296db;margin: 40px 0 20px 20px;text-align: left;">
                我们能为您做什么</div>
            <div style="text-align: left; font-size: 16px;color: #1296db; padding: 0 20px;">
                智能仪表数据云平台，引领仪表管理新时代！平台支持数字孪生技术，打造逼真的场景建模，
                为客户提供沉浸式的操作体验。实现实时数据采集，全面展示流量信息及各类告警通知，确保关键数据尽在掌握。
                此外，平台智能跟踪仪表在线状态及使用情况，可按年、季度、月、日多维度呈现数据，通过直观的饼图与柱状图轻松实现分析。
                不论是单一仪表还是整个系统，我们为客户提供高效精准的智能化管理解决方案。
            </div>

            <div style="font-size: 24px;font-weight: 700;color: #1296db;margin: 60px 0 20px 20px;text-align: left;">产品特点</div>
            <div style="padding: 0 20px;text-align: left; font-size: 16px;color: #1296db;">
                利用4G网络技术，平台实现了高效的数据传输，快速且可靠地将大量仪表数据传输至云端，确保实时性能与可靠性。
                平台在数据传输过程中采用了优化的通信协议和智能分发技术，即使在复杂的网络环境中也能降低延迟，保持数据完整性与传输效率。
                这为客户提供了稳定、无缝的远程数据访问和实时监控，全面满足高性能数据管理需求。
            </div>
        </div>

        <!-- 数字孪生部分 -->
        <div class="mobile-section">
            <div class="mobile-content">
                <div class="mobile-image">
                    <img src="../../assets/web/2.png" width="100%" />
                </div>
                <div class="mobile-text">
                    通过精准建模，我们实现了物理设备与虚拟环境的实时同步。平台支持可定制的场景建模，提供直观的可视化体验。
                    展示仪表流量、设备状态和告警信息，向客户提供高效的监控与管理体验。
                </div>
            </div>
        </div>

        <div class="mobile-section">
            <div class="mobile-content">
                <div class="mobile-image">
                    <img src="../../assets/web/3.png" width="100%" />
                </div>
                <div class="mobile-text">
                    借助数字孪生技术，客户可以动态模拟设备运行和数据流动，同时访问历史数据，大幅提升管理效率和决策能力，
                    为智能运维提供创新解决方案。
                </div>
            </div>
        </div>

        <div class="mobile-section">
            <img src="../../assets/web/4.png" width="90%" style="margin: 20px 0" />
            <div class="mobile-text" style="padding: 0 20px;">
                如有您有需要，我们可以通过先进的数字孪生技术精确复制您的工作环境。从设备布局到数据流动，
                我们的平台提供逼真的虚拟表示，支持实时数据同步和交互，提升效率并优化决策。
            </div>
        </div>

        <!-- 实时监控部分 -->
        <div style="font-size: 24px;font-weight: 700;color: #1296db;margin: 60px 0 20px 0;">实时监控仪表数据</div>
        <div style="width:100%;padding: 0 20px;">
            <img src="../../assets/web/6.png" width="100%" />
        </div>
        <div class="mobile-list">
            <div class="list-item">
                <div class="item-title">在线状态:</div>
                <div class="item-desc">仪表运行状况一目了然</div>
            </div>
            <div class="list-item">
                <div class="item-title">实时瞬时流量:</div>
                <div class="item-desc">精确捕捉每一刻的流量变化</div>
            </div>
            <div class="list-item">
                <div class="item-title">总累积量:</div>
                <div class="item-desc">精准记录仪表的累计用量</div>
            </div>
            <div class="list-item">
                <div class="item-title">正、负累计流量:</div>
                <div class="item-desc">细分正负方向累计值，数据更清晰</div>
            </div>
        </div>

        <!-- 交互式弹窗部分 -->
        <div style="font-size: 24px;font-weight: 700;color: #1296db;margin: 60px 0 20px 0;">
            交互式弹窗，深入数据分析
        </div>
        <div class="mobile-section">
            <div class="mobile-content">
                <div class="mobile-image">
                    <img src="../../assets/web/7.png" width="100%" />
                </div>
                <div class="mobile-text">
                    通过瞬时流量的历史趋势图和表格数据，累积流量和正/负累积流量，使您每一刻能够轻松跟踪仪表数据变化。
                    并且为日、月、季度、年等区间设计的统计弹窗，通过柱状图与表格结合的形式，清晰呈现不同时段的用量数据，
                    支持多维度对比分析，助您优化用能策略。
                </div>
            </div>
        </div>

        <!-- 其他特色部分 -->
        <div style="font-size: 24px;font-weight: 700;color: #1296db;margin: 60px 0 20px 0;">其它特色</div>
        <div class="feature-section">
            <div class="feature-item">
                <img src="../../assets/update.png" class="feature-icon" />
                <div class="feature-title">远程升级，让运维更轻松</div>
                <div class="feature-desc">
                    系统配备设备软件远程升级功能，无需现场操作即可一键完成设备固件的在线更新。
                    不仅缩短了维护周期，还减少了人员投入与成本支出，极大提高了设备运行的安全性和稳定性。
                </div>
            </div>

            <div class="feature-item">
                <img src="../../assets/inter.png" class="feature-icon" />
                <div class="feature-title">多语言支持，服务全球用户</div>
                <div class="feature-desc">
                    为了服务更广泛的用户群体，系统支持包括中文、英文、印度语、土耳其语等多种语言，
                    真正实现无障碍操作。用户可根据需求自由切换语言，保证管理体验的便捷性和一致性。
                </div>
            </div>

            <div class="feature-item">
                <img src="../../assets/chuangxin.png" class="feature-icon" />
                <div class="feature-title">整合创新，优化设备管理</div>
                <div class="feature-desc">
                    凭借对参数设置的灵活控制、远程升级的智能化特性，以及多语言覆盖的广泛适配能力，
                    系统不仅为流量计的管理提供了精准高效的解决方案，还通过智能化的技术手段帮助用户降低成本、优化流程。
                </div>
            </div>

            <div class="feature-item">
                <img src="../../assets/dingzhi.png" class="feature-icon" />
                <div class="feature-title">经销商专属管理权限与品牌定制服务</div>
                <div class="feature-desc">
                    本平台为经销商提供可分配终端用户的专属管理账号，实现终端用户分级管理，
                    同时支持自定义企业logo和界面标题，助力合作伙伴实现灵活运营并强化品牌形象。
                </div>
            </div>
        </div>

        <div style="margin: 60px 0">
            <img src="https://qiniu.shunmingelectronic.com/guanwang/cpmh-266095f07g.jpg" width="90%" />
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            hasUserInteracted: false,
        };
    },
    mounted() {
        window.addEventListener('scroll', this.handleUserInteraction);
        this.createIntersectionObserver();
    },
    beforeUnmount () {
        window.removeEventListener('scroll', this.handleUserInteraction);
    },
    methods: {
        handleUserInteraction() {
            this.hasUserInteracted = true;
        },
        createIntersectionObserver() {
            const videoElement = this.$refs.videoElement;

            const observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting && this.hasUserInteracted) {
                        videoElement.play().catch(error => {
                            console.log("Auto-play was prevented: ", error);
                        });
                    } else {
                        videoElement.pause();
                    }
                });
            }, {
                threshold: 0.5
            });

            observer.observe(videoElement);
        }
    }
};
</script>
<style scoped>
.product-jj {
    margin-top: 50px;
}

.product-kind-name {
    font-size: 28px;
    color: #1296db;
}

.product-text {
    padding: 50px 0px;
    color: #1296db;
    text-align: left;
    font-size:1rem;
}

.use {
    font-size: 26px;
    padding: 30px 5%;
}

.tongdian {
    display: flex;
    margin-bottom: 100px;
    margin-left: 5%;
}

.mobile-section {
    margin: 40px 0;
    padding: 0 20px;
}

.mobile-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.mobile-text {
    color: #1296db;
    text-align: left;
    font-size: 16px;
    line-height: 1.5;
}

.mobile-image {
    margin: 10px 0;
}

.mobile-list {
    padding: 20px;
}

.list-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    text-align: left;
}

.item-title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 5px;
}

.item-desc {
    font-size: 16px;
    color: #1296db;
}

.feature-section {
    padding: 0 20px;
}

.feature-item {
    margin-bottom: 40px;
    text-align: center;
}

.feature-icon {
    width: 80px;
    height: 80px;
    margin: 0 auto 15px;
    display: block;
}

.feature-title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
    text-align: center;
}

.feature-desc {
    font-size: 16px;
    line-height: 1.5;
    text-align: left;
}
</style>